import { cookies } from '@foodsby/webapp-jwt'

const cookieName = process.env.REACT_APP_COOKIE_NAME
const domain = process.env.REACT_APP_COOKIE_DOMAIN

export const storeUserInfoCookie = user =>
  cookies.addUserInfoCookie({
    cookieName,
    user,
    domain
  })

export const removeUserInfoCookie = () =>
  cookies.removeCookies({
    cookieName,
    domain
  })
