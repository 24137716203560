import * as Cookies from 'js-cookie'
import { logException } from './errorUtils'

const NO_COOKIE_ERR = 'Could not find a user info cookie'

export const pushGTMDataLayer = data =>
  window && window.dataLayer && window.dataLayer.push(data)

export async function pushGTMUserInfo () {
  let cookie
  try {
    cookie = await getUserCookie(20, 250) // 5 second timeout
  } catch (e) {
    e.message !== NO_COOKIE_ERR && logException(e)
  }

  if (window && window.dataLayer && cookie) {
    window.dataLayer.push(JSON.parse(cookie))
    pushGTMDataLayer({
      event: 'user-info-set',
      userId: JSON.parse(cookie).userId
    })
  }
}

// Helpers
const wait = ms => new Promise(r => setTimeout(r, ms))

async function getUserCookie (numAttempts, msWaitTime) {
  let cookie = Cookies.get(process.env.REACT_APP_COOKIE_NAME + '-user')
  if (cookie) {
    return cookie
  } else {
    for (var i = 0; i < numAttempts; i++) {
      await wait(msWaitTime)
      cookie = Cookies.get(process.env.REACT_APP_COOKIE_NAME + '-user')
      if (!cookie) throw new Error(NO_COOKIE_ERR)
      return cookie
    }
  }
}
