import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import appboy from 'appboy-web-sdk' // Braze used to be called appboy

import App from './App'
import { logException } from './utils/errorUtils'

// Setup appboy
appboy.initialize(process.env.REACT_APP_BRAZE_API_KEY, {
  baseUrl: 'https://rest.iad-03.braze.com/api/v3',
  enableLogging: process.env.NODE_ENV !== 'production',
  enableHtmlInAppMessages: true,
  minimumIntervalBetweenTriggerActionsInSeconds: 10,
  safariWebsitePushId: 'web.com.foodsby.order',
})
appboy.display.automaticallyShowNewInAppMessages()
appboy.openSession()
window.appboy = appboy
setInterval(() => appboy.requestContentCardsRefresh(), 15000)
if (process.env.NODE_ENV !== 'production') {
  appboy.stopWebTracking()
}

try {
  ReactDOM.render(<App />, document.getElementById('root'))
} catch (error) {
  logException(error)
}
