import React from 'react'

import AppLayout from 'components/appLayout/AppLayout'

const SplashLayout = ({
  className,
  headerType = 'primary',
  children,
  ...rest
}) => (
  <div className={`splash-layout ${className}`}>
    <AppLayout {...rest}>
      <div
        className={`splash-layout__header-pattern splash-layout__header-pattern--${headerType}`}
      />
      {children}
    </AppLayout>
  </div>
)

export default SplashLayout
