import { createAction, handleActions } from 'redux-actions'

import { loadStoresForLocation } from '../../api'
import { filterInActiveStores } from '../../utils/store'

const SET_STORES = 'foodsby/stores/SET_STORES'

export const setStores = createAction(SET_STORES)

export const getStores = locationId => {
  return dispatch => {
    return loadStoresForLocation(locationId)
      .then(filterInActiveStores)
      .then(data => dispatch(setStores(data)))
  }
}

const initialState = {
  stores: []
}

const storeReducer = handleActions(
  {
    [SET_STORES]: (state, { payload }) => {
      const stores = payload
      return {
        ...state,
        stores
      }
    }
  },
  initialState
)

export default storeReducer
