import camelCase from 'lodash/camelCase'
import flow from 'lodash/flow'
import upperFirst from 'lodash/upperFirst'

const pascalCase = flow(
  camelCase,
  upperFirst
)

const has = (arr, key) =>
  arr.some(x => (typeof x === 'string' ? x === key : x.test(key)))

const isObject = x =>
  typeof x === 'object' &&
  x !== null &&
  !(x instanceof RegExp) &&
  !(x instanceof Error) &&
  !(x instanceof Date)

const mapObj = (obj, fn, opts, seen) => {
  opts = {
    deep: false,
    target: {},
    ...opts
  }

  seen = seen || new WeakMap()

  if (seen.has(obj)) {
    return seen.get(obj)
  }

  seen.set(obj, opts.target)

  const target = opts.target
  delete opts.target

  for (const key of Object.keys(obj)) {
    const val = obj[key]
    const res = fn(key, val, obj)
    let newVal = res[1]

    if (opts.deep && isObject(newVal)) {
      if (Array.isArray(newVal)) {
        newVal = newVal.map(x => (isObject(x) ? mapObj(x, fn, opts, seen) : x))
      } else {
        newVal = mapObj(newVal, fn, opts, seen)
      }
    }

    target[res[0]] = newVal
  }

  return target
}

const caseConvert = (input, opts, converter) => {
  opts = {
    deep: false,
    ...opts
  }

  const exclude = opts.exclude

  return mapObj(
    input,
    (key, val) => {
      if (!(exclude && has(exclude, key))) {
        const ret = converter(key)
        key = ret
      }
      return [key, val]
    },
    { deep: opts.deep }
  )
}

export const pascalcaseKeys = (input, opts) => {
  if (Array.isArray(input)) {
    return Object.keys(input).map(key =>
      caseConvert(input[key], opts, pascalCase)
    )
  }
  return caseConvert(input, opts, pascalCase)
}
export const camelcaseKeys = (input, opts) => {
  if (Array.isArray(input)) {
    return Object.keys(input).map(key =>
      caseConvert(input[key], opts, camelCase)
    )
  }
  return caseConvert(input, opts, camelCase)
}
