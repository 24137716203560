import { createAction, handleActions } from 'redux-actions'

import {
  loadLocation,
  loadGroupOrderSupportedLocation,
  loadLocationFromAddress
} from 'api'
import { convertAddress } from 'utils/address'

import {
  newGroupOrder,
  selectCurrentUser,
  selectGoLocationId
} from 'redux/selectors'

const SET_LOCATION = 'foodsby/location/SET_LOCATION'
const SET_SUPPORTED_LOCATION = 'foodsby/location/SET_SUPPORTED_LOCATION'
const SET_GO_LOCATION_ID = 'foodsby/location/SET_GO_LOCATION_ID'
const SET_SEARCHED_ADDRESS = 'foodsby/location/SET_SEARCHED_ADDRESS'

export const setLocation = createAction(SET_LOCATION)
export const setSupportedLocation = createAction(SET_SUPPORTED_LOCATION)
export const setSearchedAddress = createAction(SET_SEARCHED_ADDRESS)

export const getLocation = locationId => async (dispatch, getState) => {
  const state = getState()
  if (!locationId) {
    const newGroupOrderLocationId = newGroupOrder.selectLocationId(state)
    const goLocationId = selectGoLocationId(state)
    const currentUser = selectCurrentUser(state)
    if (newGroupOrderLocationId) {
      locationId = newGroupOrderLocationId
    } else if (goLocationId) {
      locationId = goLocationId
    } else if (currentUser.locationId) {
      locationId = currentUser.locationId
    }
  }
  const data = await loadLocation(locationId)
  dispatch(setLocation(data))
  return data
}

export const setLocationFromAddress = address => async dispatch => {
  dispatch(setSearchedAddress(address))

  let result
  let location

  try {
    result = await convertAddress(address)
    location = await loadLocationFromAddress(result)
  } catch (e) {
    console.warn(`Error finding address: ${address}.`, e)
    return null
  }

  if (location.supported === false) {
    return null
  }

  await dispatch(setGoLocationId(location.locationId))

  return location.locationId
}

export const getSupportedLocation = locationId => async dispatch => {
  const data = await loadGroupOrderSupportedLocation(locationId)
  dispatch(setSupportedLocation(data))
  return data
}

export const setGoLocationId = locationId => async dispatch => {
  window.localStorage.setItem('go-location-id', locationId)
  dispatch({ type: SET_GO_LOCATION_ID, payload: locationId })
}

const initialState = {
  location: null,
  supportedLocation: {},
  searchedAddress: null,
  goLocationId: window.localStorage.getItem('go-location-id')
}

const locationReducer = handleActions(
  {
    [SET_LOCATION]: (state, { payload }) => {
      const location = payload
      return {
        ...state,
        location
      }
    },
    [SET_SUPPORTED_LOCATION]: (state, { payload }) => {
      const supportedLocation = payload
      return {
        ...state,
        supportedLocation
      }
    },
    [SET_GO_LOCATION_ID]: (state, { payload }) => ({
      ...state,
      goLocationId: payload
    }),
    [SET_SEARCHED_ADDRESS]: (state, { payload }) => ({
      ...state,
      searchedAddress: payload
    })
  },
  initialState
)

export default locationReducer
