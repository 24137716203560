import { createAction, handleActions } from 'redux-actions'
import remove from 'lodash/remove'

import track, { CLICK_EVENT, CLICK_SAVE_DEFAULT_ITEM } from 'services/tracking'
import { getTaxes, validateCoupon } from 'api'
import { NEW_MEAL_BUDGET_PATH } from 'routes'
import {
  selectNewGroupOrder,
  selectNewGroupOrderStore,
  selectCurrentUser,
  selectRestaurantNotAvailable
} from 'redux/selectors'
import { isTimeSet, isDateInRange, convertTimeToDate } from 'utils/datetime'
import { sumMealItems } from 'utils/order'
import { clearMeal } from './menu'

const SET_GROUP_ORDER = 'foodsby/new-group-order/SET_GROUP_ORDER'
const UPDATE_GROUP_ORDER = 'foodsby/new-group-order/UPDATE_GROUP_ORDER'
const SET_DEFAULT_MEAL = 'foodsby/new-group-order/SET_DEFAULT_MEAL'
const DELETE_DEFAULT_MEAL_MENU_ITEM =
  'foodsby/new-group-order/DELETE_DEFAULT_MEAL_MENU_ITEM'
const ADD_TO_DEFAULT_MEAL = 'foodsby/new-group-order/ADD_TO_DEFAULT_MEAL'
const CHANGE_DEFAULT_MEAL = 'foodsby/new-group-order/CHANGE_DEFAULT_MEAL'
const SET_BUDGET = 'foodsby/new-group-order/SET_BUDGET'
const SET_NO_BUDGET = 'foodsby/new-group-order/SET_NO_BUDGET'
const SET_CONTACT_INFO = 'foodsby/new-group-order/SET_CONTACT_INFO'
const SET_DROPOFF_INSTRUCTIONS =
  'foodsby/new-group-order/SET_DROPOFF_INSTRUCTIONS'
const SET_TAXES = 'foodsby/new-group-order/SET_TAXES'
const SET_COUPON = 'foodsby/new-group-order/SET_COUPON'
const CLEAR_GROUP_ORDER = 'foodsby/new-group-order/CLEAR_GROUP_ORDER'
const SET_BACKUP_MEAL_WARNING_ACKNOWLEDGED =
  'foodsby/new-group-order/SET_BACKUP_MEAL_WARNING_ACKNOWLEDGED'
const SET_RESTAURANT_NOT_AVAILABLE =
  'foodsby/new-group-order/SET_RESTAURANT_NOT_AVAILABLE'
const SET_HAS_DATE_ERROR = 'foodsby/new-group-order/SET_HAS_DATE_ERROR'
const SET_CURRENT_STEP = 'foodsby/new-group-order/SET_CURRENT_STEP'
const SET_DELIVERY_OPTIONS_INVALID =
  'foodsby/new-group-order/SET_DELIVERY_OPTIONS_INVALID'
const SET_DELIVERY_OPTIONS_FORM_OPEN =
  'foodsby/new-group-order/SET_DELIVERY_OPTIONS_FORM_OPEN'

export const setNewGroupOrder = createAction(SET_GROUP_ORDER)
export const updateGroupOrder = createAction(UPDATE_GROUP_ORDER)
export const deleteDefaultMealMenuItem = createAction(
  DELETE_DEFAULT_MEAL_MENU_ITEM
)
export const addToDefaultMeal = createAction(ADD_TO_DEFAULT_MEAL)
export const setBudget = createAction(SET_BUDGET)
export const setNoBudget = createAction(SET_NO_BUDGET)
export const setContactInfo = createAction(SET_CONTACT_INFO)
export const setDropoffInstructions = createAction(SET_DROPOFF_INSTRUCTIONS)
export const setTaxes = createAction(SET_TAXES)
export const setCoupon = createAction(SET_COUPON)
export const changeDefaultMeal = createAction(CHANGE_DEFAULT_MEAL)
export const clearGroupOrder = createAction(CLEAR_GROUP_ORDER)
export const setRestaurantNotAvailable = createAction(
  SET_RESTAURANT_NOT_AVAILABLE
)
export const setHasDateError = createAction(SET_HAS_DATE_ERROR)
export const setBackupMealWarningAcknowledged = createAction(
  SET_BACKUP_MEAL_WARNING_ACKNOWLEDGED
)
export const setCurrentOrderStep = createAction(SET_CURRENT_STEP)
export const setDeliveryOptionsInvalid = createAction(
  SET_DELIVERY_OPTIONS_INVALID
)
export const setDeliveryOptionsFormOpen = createAction(
  SET_DELIVERY_OPTIONS_FORM_OPEN
)

export const setGroupOrder = order => (dispatch, getState) => {
  const { storeId } = selectNewGroupOrder(getState())

  if (storeId !== order.storeId) {
    dispatch(clearMeal())
  }
  dispatch(setNewGroupOrder(order))
}

export const saveDefaultMeal = (meal, history, shouldRedirect = true) => async (
  dispatch,
  getState
) => {
  const {
    maxHeadCount,
    startDeliveryTime,
    endDeliveryTime
  } = selectNewGroupOrderStore(getState())
  const { locationId, storeId, dropoff, attendeesCount } = selectNewGroupOrder(
    getState()
  )

  // make sure delivery options form invalidation is reset
  dispatch(setDeliveryOptionsInvalid(false))

  // Set the start/end delivery times to the dropoff date for comparison
  const startDate = convertTimeToDate(startDeliveryTime, dropoff)
  const endDate = convertTimeToDate(endDeliveryTime, dropoff)

  if (
    !attendeesCount ||
    attendeesCount <= 0 ||
    !(dropoff && isTimeSet(new Date(dropoff)))
  ) {
    dispatch(setDeliveryOptionsInvalid(true))
  } else if (
    attendeesCount > maxHeadCount ||
    !isDateInRange(dropoff, startDate, endDate)
  ) {
    if (attendeesCount > maxHeadCount)
      dispatch(
        setRestaurantNotAvailable({
          ...selectRestaurantNotAvailable(getState()),
          attendeesCount: true
        })
      )

    if (!isDateInRange(dropoff, startDate, endDate))
      dispatch(
        setRestaurantNotAvailable({
          ...selectRestaurantNotAvailable(getState()),
          time: true
        })
      )
  } else {
    await dispatch(changeDefaultMeal(meal))

    track({
      category: CLICK_EVENT,
      action: CLICK_SAVE_DEFAULT_ITEM
    })

    let { budgetPerAttendeeInCents, coupon } = selectNewGroupOrder(getState())

    // re-validate coupon to get updated discount amounts
    if (coupon && coupon.valid) {
      const mealCostInCents = sumMealItems(meal).intValue

      const { userId } = selectCurrentUser(getState())

      const body = {
        code: coupon.code,
        userId,
        locationId,
        storeId,
        actualFoodCostInCents: attendeesCount * mealCostInCents,
        budgetedFoodCostInCents: attendeesCount * budgetPerAttendeeInCents
      }

      coupon = await dispatch(applyCoupon(body))
    }

    await dispatch(
      calculateMealTaxes(
        meal,
        locationId,
        storeId,
        dropoff,
        attendeesCount,
        budgetPerAttendeeInCents,
        coupon
      )
    )

    if (shouldRedirect) {
      history.push(NEW_MEAL_BUDGET_PATH)
    }
  }
}

export const calculateMealTaxes = (
  meal,
  locationId,
  storeId,
  deliveryDate,
  attendeesCount,
  budgetPerAttendeeInCents,
  coupon
) => {
  const mealPrice = sumMealItems(meal)

  const actualFoodCostInCents = mealPrice.multiply(attendeesCount).intValue
  const budgetedFoodCostInCents = budgetPerAttendeeInCents * attendeesCount

  let actualDiscount = null
  let budgetedDiscount = null

  if (coupon && coupon.valid) {
    actualDiscount = coupon.discount.actualDiscount
    budgetedDiscount = coupon.discount.budgetedDiscount
  }

  return calculateTaxes(
    locationId,
    storeId,
    deliveryDate,
    actualFoodCostInCents,
    actualDiscount,
    budgetedFoodCostInCents,
    budgetedDiscount
  )
}

/*
  Calculate taxes with existing data from the Redux store
  Does and returns nothing if the data is not available
*/
export const recalculateTaxes = coupon => async (dispatch, getState) => {
  const {
    locationId,
    storeId,
    defaultMeal,
    dropoff,
    attendeesCount,
    budgetPerAttendeeInCents
  } = selectNewGroupOrder(getState())

  if (!defaultMeal) return

  const mealPrice = sumMealItems(defaultMeal)

  const actualFoodCostInCents = mealPrice.multiply(attendeesCount).intValue
  const budgetedFoodCostInCents = budgetPerAttendeeInCents * attendeesCount

  let actualDiscount = null
  let budgetedDiscount = null

  if (coupon && coupon.valid) {
    actualDiscount = coupon.discount.actualDiscount
    budgetedDiscount = coupon.discount.budgetedDiscount
  }

  return dispatch(
    calculateTaxes(
      locationId,
      storeId,
      dropoff,
      actualFoodCostInCents,
      actualDiscount,
      budgetedFoodCostInCents,
      budgetedDiscount
    )
  )
}

export const calculateTaxes = (
  locationId,
  storeId,
  deliveryDate,
  actualFoodCostInCents,
  actualDiscount,
  budgetedFoodCostInCents,
  budgetedDiscount
) => async dispatch => {
  const actualTaxes = await getTaxes(
    storeId,
    locationId,
    deliveryDate,
    actualFoodCostInCents,
    actualDiscount ? actualDiscount.foodCostAmountInCents : null,
    actualDiscount ? actualDiscount.feeAmountInCents : null
  )
  const budgetedTaxes = await getTaxes(
    storeId,
    locationId,
    deliveryDate,
    budgetedFoodCostInCents,
    budgetedDiscount ? budgetedDiscount.foodCostAmountInCents : null,
    budgetedDiscount ? budgetedDiscount.feeAmountInCents : null
  )
  dispatch(setTaxes({ actualTaxes, budgetedTaxes }))
}

const adjustBudget = (defaultMeal, budgetPerAttendeeInCents) => {
  const totalPrice = sumMealItems(defaultMeal)

  if (
    totalPrice.intValue > budgetPerAttendeeInCents ||
    budgetPerAttendeeInCents === undefined
  ) {
    return Math.ceil(totalPrice.value) * 100
  } else {
    return budgetPerAttendeeInCents
  }
}

/*
// TODO: update
onChangeBudget = async budgetPerAttendeeInCents => {
  const {
    calculateMealTaxes,
    defaultMeal,
    currentUser,
    currentLocation,
    currentStore,
    dropoff,
    attendeesCount,
    setBudget
  } = this.props

  let coupon = this.props.coupon

  setBudget(budgetPerAttendeeInCents)

  // re-validate coupon
  if (coupon && coupon.valid) {
    const mealCostInCents = sumMealItems(defaultMeal).intValue

    const body = {
      code: coupon.code,
      userId: currentUser.userId,
      locationId: currentLocation.deliveryLocationId,
      storeId: currentStore.id,
      actualFoodCostInCents: attendeesCount * mealCostInCents,
      budgetedFoodCostInCents: attendeesCount * budgetPerAttendeeInCents
    }

    coupon = await this.props.applyCoupon(body)
  }

  calculateMealTaxes(
    defaultMeal,
    currentLocation.deliveryLocationId,
    currentStore.id,
    dropoff,
    attendeesCount,
    budgetPerAttendeeInCents,
    coupon
  )
}
*/

export const applyCoupon = body => async dispatch => {
  try {
    const couponResponse = await validateCoupon(body)

    const coupon = {
      code: body.code,
      valid: couponResponse.valid,
      errors: couponResponse.errors,
      discount: couponResponse.discount
    }
    dispatch(setCoupon(coupon))
    return coupon
  } catch (errResponse) {
    if (errResponse && errResponse.code >= 400 && errResponse.code < 500) {
      // coupon does not exist
      dispatch(
        setCoupon({
          code: body.code,
          valid: false,
          error: 'Invalid coupon',
          discount: null
        })
      )
    } else {
      throw errResponse
    }
  }
}

export const clearCoupon = () => async (dispatch, getState) => {
  dispatch(setCoupon(null))

  // Recalculate taxes since a coupon changes the total food cost:
  const {
    locationId,
    storeId,
    dropoff,
    attendeesCount,
    budgetPerAttendeeInCents,
    defaultMeal
  } = selectNewGroupOrder(getState())

  dispatch(
    calculateMealTaxes(
      defaultMeal,
      locationId,
      storeId,
      dropoff,
      attendeesCount,
      budgetPerAttendeeInCents,
      null
    )
  )
}

const initialState = {
  groupOrder: {},
  restaurantNotAvailable: {},
  hasDateError: false,
  backupMealWarningAcknowledged: false,
  invalidateDeliveryOptionsForm: false,
  deliveryOptionsFormOpen: false
}

const groupOrderReducer = handleActions(
  {
    [SET_DEFAULT_MEAL]: (state, { payload }) => {
      const defaultMeal = payload
      return {
        ...state,
        defaultMeal
      }
    },
    [SET_CONTACT_INFO]: (state, { payload }) => {
      const {
        contactName = state.contactName,
        contactPhoneNumber = state.contactPhoneNumber,
        contactPhoneExtension = state.contactPhoneExtension,
        contactEmail = state.contactEmail,
        suiteNumber = state.suiteNumber
      } = payload

      const newState = {
        ...state,
        contactName,
        contactPhoneNumber,
        contactPhoneExtension,
        contactEmail,
        suiteNumber
      }

      return newState
    },
    [DELETE_DEFAULT_MEAL_MENU_ITEM]: (state, { payload }) => {
      const index = payload
      const updatedDefaultMeal = remove(
        state.defaultMeal,
        (m, i) => i !== index
      )
      return {
        ...state,
        defaultMeal: updatedDefaultMeal
      }
    },
    [CHANGE_DEFAULT_MEAL]: (state, { payload }) => {
      const newState = {
        ...state,
        defaultMeal: payload
      }
      newState.budgetPerAttendeeInCents = adjustBudget(
        newState.defaultMeal,
        newState.budgetPerAttendeeInCents
      )
      return newState
    },
    [ADD_TO_DEFAULT_MEAL]: (state, { payload }) => {
      const mealItem = payload

      const newState = {
        ...state,
        defaultMeal: [...state.defaultMeal, mealItem]
      }

      newState.budgetPerAttendeeInCents = adjustBudget(
        newState.defaultMeal,
        newState.budgetPerAttendeeInCents
      )

      return newState
    },
    [SET_BUDGET]: (state, { payload }) => {
      const budgetPerAttendeeInCents = payload
      return {
        ...state,
        budgetPerAttendeeInCents
      }
    },
    [SET_NO_BUDGET]: (state, { payload }) => {
      const noBudget = payload
      return {
        ...state,
        noBudget
      }
    },
    [SET_DROPOFF_INSTRUCTIONS]: (state, { payload }) => {
      const dropoffInstructions = payload
      return {
        ...state,
        dropoffInstructions
      }
    },
    [SET_TAXES]: (state, { payload }) => {
      const taxes = payload
      return {
        ...state,
        taxes
      }
    },
    [SET_COUPON]: (state, { payload }) => {
      const coupon = payload
      return {
        ...state,
        coupon
      }
    },
    [CLEAR_GROUP_ORDER]: state => {
      return {}
    },
    [UPDATE_GROUP_ORDER]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {}
)

const newGroupOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUP_ORDER:
      const shouldChangeDropoff = !(
        state.groupOrder &&
        !!state.groupOrder.dropoff &&
        !action.payload.dropoff
      )

      return {
        ...state,
        groupOrder: {
          ...action.payload,
          dropoff: shouldChangeDropoff
            ? action.payload.dropoff
            : state.groupOrder.dropoff,
          attendeesCount:
            action.payload.attendeesCount > 0
              ? action.payload.attendeesCount
              : state.groupOrder.attendeesCount
        },
        backupMealWarningAcknowledged: false
      }
    case SET_RESTAURANT_NOT_AVAILABLE:
      return {
        ...state,
        restaurantNotAvailable: action.payload
      }
    case SET_HAS_DATE_ERROR:
      return {
        ...state,
        hasDateError: action.payload
      }
    case SET_BACKUP_MEAL_WARNING_ACKNOWLEDGED:
      return {
        ...state,
        backupMealWarningAcknowledged: action.payload
      }
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentOrderStep: action.payload
      }
    case SET_DELIVERY_OPTIONS_INVALID:
      return {
        ...state,
        invalidateDeliveryOptionsForm: action.payload
      }
    case SET_DELIVERY_OPTIONS_FORM_OPEN:
      return {
        ...state,
        deliveryOptionsFormOpen: action.payload
      }

    default:
      return {
        ...state,
        groupOrder: groupOrderReducer(state.groupOrder, action)
      }
  }
}

export default newGroupOrderReducer
