import { Component } from 'react'
import stickyfilljs from 'stickyfilljs'

const stick = () => {
  stickyfilljs.removeAll()
  stickyfilljs.add(document.querySelectorAll('.sticky'))
}

class Sticky extends Component {
  componentDidMount () {
    stick()
  }
  componentDidUpdate () {
    stick()
  }
  render () {
    return this.props.children
  }
}

export default Sticky
