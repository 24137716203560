import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import ScrollToTop from 'components/scrollToTop/ScrollToTop'
import Sticky from 'components/newOrderWorkflow/shared/Sticky'
import AppErrorPage from 'components/appErrorPage/AppErrorPage'
import ShutdownSplashPage from './components/shutdownSplashPage/ShutdownSplashPage'
import store from 'redux/store'
import { ERROR_PATH, SHUTDOWN_SPLASH_PATH, PAGE_NOT_FOUND } from 'routes'
import Router from 'router'
import 'app.css'

import '@foodsby/nutrient-v3/src/css/app.css'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './createMuiTheme'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
const history = createBrowserHistory()

history.listen(location => {
  window.ga('set', 'page', location.pathname + location.search)
  window.ga('send', 'pageview')
})

class App extends Component {
  render () {
    return (
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <ScrollToTop>
              <Sticky>
                <Switch>
                  <Route path={SHUTDOWN_SPLASH_PATH} component={ShutdownSplashPage} />
                  <Route path={ERROR_PATH} component={AppErrorPage} />
                  <Route
                    path={PAGE_NOT_FOUND}
                    render={props => <AppErrorPage error={{ code: 404 }} />}
                  />
                  <Redirect to={SHUTDOWN_SPLASH_PATH} />
                </Switch>
              </Sticky>
            </ScrollToTop>
          </Router>
        </ThemeProvider>
      </Elements>
    )
  }
}

const withStore = WrappedComponent => props => (
  <Provider store={store}>
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  </Provider>
)

class ErrorBoundary extends Component {
  render () {
    const { children } = this.props
    return children
  }
}

export default withStore(App)
