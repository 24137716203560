import { createAction, handleActions } from 'redux-actions'

import { setLoading } from './application'
import { register, login } from 'utils/api'

const SET_ACCESS_TOKEN = 'foodsby/auth/SET_ACCESS_TOKEN'

export const setAccessToken = createAction(SET_ACCESS_TOKEN)

export const attendeeLogin = (username, password) => async dispatch => {
  dispatch(setLoading(true))
  try {
    const tokens = await login(username, password)
    dispatch(setAccessToken(tokens.access_token))
  } finally {
    dispatch(setLoading(false))
  }
}

export const attendeeRegister = body => async dispatch => {
  dispatch(setLoading(true))
  try {
    await register(body)
    const tokens = await login(body.email, body.password)
    dispatch(setAccessToken(tokens.access_token))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  accessToken: null
}

const authReducers = handleActions(
  {
    [SET_ACCESS_TOKEN]: (state, { payload }) => ({
      ...state,
      accessToken: payload
    })
  },
  initialState
)

export default authReducers
