import React from 'react'

import AppFooter from 'components/appFooter/AppFooter'

const AppLayout = ({ currentUser, headerProps, children }) => (
  <>
    {children}
    <AppFooter />
  </>
)
export default AppLayout
