import { createAction, handleActions } from 'redux-actions'
import map from 'lodash/map'
import { differenceInCalendarDays } from 'date-fns'

import {
  loadAllGroupOrders,
  loadGroupOrder,
  createGroupOrder as apiCreateGroupOrder,
  updateGroupOrder as apiUpdateGroupOrder,
  createAttendee,
  deleteAttendee,
  assignAttendee,
  updateDefaultMeal as apiUpdateDefaultMeal,
  loadAttendeeGroupOrders,
  updateCreditCard as apiUpdateCreditCard,
  processGroupOrder as apiProcessGroupOrder
} from 'api'
import { pushGTMDataLayer } from 'utils/gtmUtils'
import { logException } from 'utils/errorUtils'

const SET_GROUP_ORDER_LIST = 'foodsby/group-order/SET_GROUP_ORDER_LIST'
const SET_GROUP_ORDER = 'foodsby/group-order/SET_GROUP_ORDER'
const SET_GROUP_ORDER_ATTENDEE = 'foodsby/group-order/SET_GROUP_ORDER_ATTENDEE'
const SET_DEFAULT_MEAL = 'foodsby/group-order/SET_DEFAULT_MEAL'
const DELETE_DEFAULT_MEAL_MENU_ITEM =
  'foodsby/group-order/DELETE_DEFAULT_MEAL_MENU_ITEM'
const SET_HAS_AUTH_ERROR = 'foodsby/group-order/SET_HAS_AUTH_ERROR'
const SET_ATTENDEE_GROUP_ORDERS =
  'foodsby/group-order/SET_ATTENDEE_GROUP_ORDERS'
const SET_CC_UPDATE_ERROR = 'foodsby/group-order/SET_CC_UPDATE_ERROR'

export const setGroupOrder = createAction(SET_GROUP_ORDER)
export const setGroupOrderList = createAction(SET_GROUP_ORDER_LIST)
export const setAttendeeGroupOrders = createAction(SET_ATTENDEE_GROUP_ORDERS)
export const setGroupOrderAttendee = createAction(SET_GROUP_ORDER_ATTENDEE)
export const setDefaultMeal = createAction(SET_DEFAULT_MEAL)
export const deleteDefaultMealMenuItem = createAction(
  DELETE_DEFAULT_MEAL_MENU_ITEM
)
export const setHasAuthError = createAction(SET_HAS_AUTH_ERROR)
export const setCCUpdateError = createAction(SET_CC_UPDATE_ERROR)

export const addAttendee = groupOrderId => {
  return dispatch => {
    return createAttendee(groupOrderId).then(data => {
      dispatch(setGroupOrder(data))
    })
  }
}

export const removeAttendee = (groupOrderId, attendeeId) => {
  return dispatch => {
    return deleteAttendee(groupOrderId, attendeeId).then(data => {
      dispatch(setGroupOrder(data))
    })
  }
}

export const setAttendee = (groupOrderId, body) => async dispatch => {
  const data = await assignAttendee(groupOrderId, body)
  dispatch(setGroupOrderAttendee(data))
  return data
}

export const getAllGroupOrders = () => {
  return dispatch => {
    return loadAllGroupOrders().then(data => {
      dispatch(setGroupOrderList(data))
    })
  }
}

export const getAttendeeGroupOrders = () => {
  return async dispatch => {
    const groupOrders = await loadAttendeeGroupOrders()
    dispatch(setAttendeeGroupOrders(groupOrders))
  }
}

export const getGroupOrder = groupOrderId => async dispatch => {
  const data = await loadGroupOrder(groupOrderId)
  dispatch(setGroupOrder(data))
  return data
}

export const createGroupOrder = groupOrder => async dispatch => {
  const data = await apiCreateGroupOrder(groupOrder)
  try {
    pushGTMDataLayer({
      event: 'purchase',
      type: 'group',
      orderDeliveryDaysFromToday: differenceInCalendarDays(
        data.dropoff,
        new Date()
      ),
      orderId: data.id,
      storeId: data.store.id,
      storeName: data.store.name,
      locationId: data.monolithLocationId,
      attendees: data.attendees.length,
      budgetedCost:
        data.pricing.budgetedCost && data.pricing.budgetedCost.totalInCents,
      actualCost: data.pricing.actualCost.totalInCents
    })
  } catch (ex) {
    logException(ex)
  }
  dispatch(setGroupOrder(data))
  return data
}

export const updateGroupOrder = (groupOrderId, body) => async dispatch => {
  const data = await apiUpdateGroupOrder(groupOrderId, body)
  dispatch(setGroupOrder(data))
}

export const updateDefaultMeal = (groupOrderId, body) => async dispatch => {
  const data = await apiUpdateDefaultMeal(groupOrderId, body)
  dispatch(setGroupOrder(data))
}

export const updateCreditCard = (groupOrderId, body) => async dispatch => {
  await apiUpdateCreditCard(groupOrderId, body)
}

export const processGroupOrder = groupOrderId => async dispatch => {
  await apiProcessGroupOrder(groupOrderId)
}

const initialState = {
  groupOrder: null,
  groupOrderList: [],
  attendeeGroupOrders: [],
  hasAuthError: false,
  ccUpdateError: null
}

const groupOrderReducer = handleActions(
  {
    [SET_CC_UPDATE_ERROR]: (state, { payload }) => ({
      ...state,
      ccUpdateError: payload
    }),
    [SET_HAS_AUTH_ERROR]: (state, { payload = true }) => ({
      ...state,
      hasAuthError: payload
    }),
    [SET_GROUP_ORDER]: (state, { payload }) => {
      const groupOrder = payload
      return {
        ...state,
        groupOrder
      }
    },
    [SET_GROUP_ORDER_LIST]: (state, { payload }) => {
      const groupOrderList = payload
      return {
        ...state,
        groupOrderList
      }
    },
    [SET_ATTENDEE_GROUP_ORDERS]: (state, { payload }) => ({
      ...state,
      attendeeGroupOrders: payload
    }),
    [SET_GROUP_ORDER_ATTENDEE]: (state, { payload }) => {
      const updatedAttendee = payload
      const attendees = state.groupOrder.attendees
      const updatedAttendees = map(attendees, att =>
        att.id === updatedAttendee.id ? updatedAttendee : att
      )

      return {
        ...state,
        groupOrder: {
          ...state.groupOrder,
          attendees: updatedAttendees
        }
      }
    }
  },
  initialState
)

export default groupOrderReducer
