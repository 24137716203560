import { createSelector } from 'reselect'
import { isTimeSet } from 'utils/datetime'

export const selectIsLoading = state => state.application.isLoading

export const selectAccessToken = state => state.auth.accessToken

export const selectConfig = state => state.config.config

export const selectCurrentUser = state => state.user.currentUser

export const selectIsLoadingUser = state => state.user.isLoading

export const selectUserProfile = state => state.user.userProfile

export const selectPayments = state => state.user.payments

export const selectStores = state => state.store.stores

export const selectStoresLoading = state => state.store.loading

export const selectMenu = state => state.menu.menu

export const selectMenuItem = state => state.menu.menuItem

export const selectMeal = state => state.menu.meal

export const selectGroupOrderList = state => state.groupOrder.groupOrderList

export const selectAttendeeGroupOrders = state =>
  state.groupOrder.attendeeGroupOrders

export const selectGroupOrder = state => state.groupOrder.groupOrder

export const isGroupOrderOwner = state => {
  const groupOrder = selectGroupOrder(state)
  return groupOrder && groupOrder.owner.id === state.user.currentUser.userId
}

export const selectDefaultMeal = state => state.groupOrder.defaultMeal

export const selectUserLocation = state => state.location.location

export const selectLocation = state => state.location.location

export const selectSupportedLocation = state => state.location.supportedLocation

export const selectSearchedAddress = state => state.location.searchedAddress

export const selectGoLocationId = state => state.location.goLocationId

// ------ NEW GROUP ORDER SELECTORS ------
export const selectNewGroupOrder = state => state.newGroupOrder.groupOrder

const newGroupOrderSelectStoreId = createSelector(
  [selectNewGroupOrder],
  newGroupOrder => newGroupOrder.storeId
)

export const selectRestaurantNotAvailable = state =>
  state.newGroupOrder.restaurantNotAvailable

export const selectNewGroupOrderStore = createSelector(
  [newGroupOrderSelectStoreId, selectStores],
  (storeId, stores) => stores.find(store => store.id === storeId)
)

export const selectHasAuthError = state => state.groupOrder.hasAuthError

export const selectBackupMealWarningAcknowledged = state =>
  state.newGroupOrder.backupMealWarningAcknowledged

export const selectDeliveryOptionsFormOpen = state =>
  state.newGroupOrder.deliveryOptionsFormOpen

// Selectors related to creating a Group Order
export const newGroupOrder = {
  selectBudgetPerAttendeeInCents: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.budgetPerAttendeeInCents
  ),

  selectNoBudget: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.noBudget
  ),

  selectContactName: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.contactName
  ),

  selectContactPhoneNumber: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.contactPhoneNumber
  ),

  selectContactPhoneExtension: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.contactPhoneExtension
  ),

  selectContactEmail: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.contactEmail
  ),

  selectSuiteNumber: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.suiteNumber
  ),

  selectDropoffInstructions: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.dropoffInstructions
  ),

  selectAttendeesCount: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.attendeesCount
  ),

  selectDropoff: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.dropoff
  ),

  selectLocationId: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.locationId
  ),

  selectLocation: state => state.location.location,

  selectStoreId: newGroupOrderSelectStoreId,

  selectStore: selectNewGroupOrderStore,

  selectDefaultMeal: createSelector(
    [selectNewGroupOrder],
    newGroupOrder => newGroupOrder.defaultMeal
  ),

  selectDeliveryOptionsInvalid: state =>
    state.newGroupOrder.invalidateDeliveryOptionsForm,

  selectHasDateError: state => state.newGroupOrder.hasDateError,

  selectTaxes: state => state.newGroupOrder.groupOrder.taxes,

  selectCoupon: state => state.newGroupOrder.groupOrder.coupon,

  selectCurrentStep: state => state.newGroupOrder.currentOrderStep,

  selectDeliveryOptionsValid: createSelector(
    [selectNewGroupOrder, selectNewGroupOrderStore],
    (newGroupOrder, store) => {
      return (
        store &&
        newGroupOrder &&
        newGroupOrder.attendeesCount > 0 &&
        newGroupOrder.attendeesCount <= store.maxHeadCount &&
        newGroupOrder.dropoff &&
        isTimeSet(newGroupOrder.dropoff)
      )
    }
  )
}

export const selectNavAlert = state => state.alerts.navAlert
