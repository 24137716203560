import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import reject from 'lodash/reject'
import flatten from 'lodash/flatten'
import times from 'lodash/times'
import reduce from 'lodash/reduce'
import max from 'lodash/max'

import { fromCents } from 'utils/money'

export const isPastCutoff = groupOrder => ['COMMITTED', 'AUTHORIZED'].includes(groupOrder.status)

export const isPastDropoff = groupOrder => groupOrder.status === 'DELIVERED'

export const isEditable = groupOrder =>
  !isPastCutoff(groupOrder) && ['INITIALIZED', 'VERIFIED'].includes(groupOrder.status)

export const isCancelled = groupOrder => groupOrder.status === 'CANCELED'

export const isPaymentError = groupOrder =>
  groupOrder.status === 'CHARGED_FAILED' || groupOrder.status === 'AUTHORIZED_FAILED'

export const isCommitted = groupOrder => groupOrder.status === 'COMMITTED'

export const isDelivered = groupOrder =>
  (isPastDropoff(groupOrder) && groupOrder.status === 'COMMITTED') ||
  groupOrder.status === 'DELIVERED'

export const isDefaultMeal = (defaultMeals, attendeeMeals) => {
  const defaultMealIds = map(defaultMeals, 'id')
  const attendeeMealIds = map(attendeeMeals, 'id')
  return isEqual(defaultMealIds, attendeeMealIds)
}

export const canUpdatePayment = groupOrder =>
  ['INITIALIZED', 'VERIFIED', 'AUTHORIZED_FAILED', 'CHARGED_FAILED'].includes(groupOrder.status)

export const getMealsToDisplay = ({ defaultMeals, meals }) =>
  isDefaultMeal(defaultMeals, meals) ? defaultMeals : meals

export const displayMeal = ({ defaultMeals, meals }) =>
  isDefaultMeal(defaultMeals, meals)
    ? `Backup Meal: ${toDisplayMeal(defaultMeals)}`
    : toDisplayMeal(meals)

export const toDisplayMeal = meals => map(meals, menuItem => menuItem.name).join(', ')

export const getCustomizations = mealItem => {
  let customizations = map(mealItem.modifiers, 'answers')
  customizations = reject(customizations, a => a.length < 1)
  customizations = flatten(customizations)
  return customizations
}

export const sumMealItems = mealItems =>
  reduce(mealItems, (sum, mealItem) => sum.add(fromCents(mealItem.totalPriceInCents)), fromCents(0))

export const getHighestMealCost = attendees =>
  max(map(attendees, attendee => sumMealItems(attendee.meals).intValue))

export const shareLinkBaseUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? ':' + window.location.port : ''
}`

export const getOrderStatus = groupOrder => {
  const status = {
    label: '',
    error: false,
  }

  if (isCancelled(groupOrder)) {
    status.label = 'Canceled'
  } else if (isPaymentError(groupOrder)) {
    status.label = 'Payment Error'
    status.error = true
  } else if (isDelivered(groupOrder)) {
    status.label = 'Delivered'
  } else if (isPastCutoff(groupOrder)) {
    status.label = 'Locked'
  } else if (isEditable(groupOrder)) {
    status.label = 'Scheduled'
  } else {
    console.error('Unknown group order status', groupOrder.status)
    status.label = 'Order Error'
    status.error = true
  }

  return status
}

export const calculateBackupMealTotal = meals =>
  reduce(
    meals,
    (sum, meal) => {
      return sum.add(meal.totalPriceInCents)
    },
    fromCents(0),
  )

export const calculateCartTotals = ({
  attendeesCount,
  budgetPerAttendeeInCents,
  coupon,
  currentStore,
  defaultMeals,
  taxes,
}) => {
  const orders = times(attendeesCount, () => defaultMeals)

  const budgetedMealTotal = fromCents(budgetPerAttendeeInCents * attendeesCount)

  const actualMealTotal = reduce(
    orders,
    (sum, mealItems) => {
      return sum.add(sumMealItems(mealItems))
    },
    fromCents(0),
  )

  let deliveryFee
  let actualTaxes
  let actualTotal
  let budgetedTaxes
  let budgetedTotal
  let actualOrderFee
  let budgetedOrderFee
  let storeMinimum
  let actualCouponDiscount
  let budgetedCouponDiscount

  if (coupon && coupon.valid) {
    actualCouponDiscount = fromCents(coupon.discount.actualDiscount.totalDiscountAmountInCents)
    budgetedCouponDiscount = fromCents(coupon.discount.budgetedDiscount.totalDiscountAmountInCents)
  }

  deliveryFee = fromCents(currentStore.deliveryFeeInCents)

  actualTaxes = taxes.actualTaxes.taxTotalInCents
    ? fromCents(taxes.actualTaxes.taxTotalInCents)
    : fromCents(0)
  actualTotal = actualMealTotal
    .subtract(actualCouponDiscount)
    .add(deliveryFee)
    .add(actualTaxes)

  budgetedTaxes = taxes.budgetedTaxes.taxTotalInCents
    ? fromCents(taxes.budgetedTaxes.taxTotalInCents)
    : fromCents(0)
  budgetedTotal = budgetedMealTotal
    .subtract(budgetedCouponDiscount)
    .add(deliveryFee)
    .add(budgetedTaxes)

  storeMinimum = fromCents(currentStore.minimumPriceInCents)

  actualOrderFee = storeMinimum.subtract(actualMealTotal)
  actualOrderFee = actualOrderFee.intValue < 0 ? fromCents(0) : actualOrderFee

  budgetedOrderFee = storeMinimum.subtract(budgetedMealTotal)
  budgetedOrderFee = budgetedOrderFee.intValue < 0 ? fromCents(0) : budgetedOrderFee

  actualTotal = actualTotal.add(actualOrderFee)
  budgetedTotal = budgetedTotal.add(budgetedOrderFee)

  const totals = {
    actualMealTotal,
    budgetedMealTotal,
    actualTaxes,
    budgetedTaxes,
    actualTotal,
    budgetedTotal,
    actualCouponDiscount,
    budgetedCouponDiscount,
    deliveryFee,
    actualOrderFee,
    budgetedOrderFee,
    storeMinimum,
  }

  return totals
}
