import React from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

export const history = createBrowserHistory()

const BrowserRouter = ({ children }) => (
  <Router history={history} children={children} />
)

export default BrowserRouter
