import {
  api as foodsbyApi,
  login as foodsbyLogin,
  register as foodsbyRegister,
  HttpError
} from '@foodsby/webapp-jwt'
import 'cross-storage'

import { camelcaseKeys } from 'utils/keys'
import { logException } from 'utils/errorUtils'
import { history } from 'router'
import { ERROR_PATH } from 'routes'

const baseURL = process.env.REACT_APP_BASE_URL

/* Error messages */
export const BAD_CREDS = 'Invalid username or password.'
export const SERVICE_UNAVAILABLE =
  'There was an error contacting our system. Please try again in a moment.'
export const NOT_FOUND = 'The requested resource was not found.'
export const INVALID_TOKEN_ERROR =
  'Your access token is either invalid or expired.'
export const GENERIC_ERROR = 'An error occurred.'
export const INVALID_GRANT_TYPE = 'Invalid grant type.'

/* Error statuses from services */
export const INVALID_GRANT = 'invalid_grant'
export const UNAUTHORIZED = 'unauthorized'
export const SERVER_ERROR = 'internal_server_error'
export const INVALID_TOKEN = 'invalid_token'
export const ACCESS_TOKEN_EXPIRED = 'Access token expired'
export const BAD_CREDENTIALS = 'Bad credentials'
export const CONFLICT = 'conflict'

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

const camelize = data => {
  if (data && !data.access_token && typeof data === 'object') {
    try {
      data = camelcaseKeys(data, { deep: true })
    } catch (ex) {
      logException(ex)
    }
  }
  return data
}

const api = {
  ...foodsbyApi,
  request: (endpoint, method, body, config = {}) => {
    config = {
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      baseURL,
      ...config
    }
    return foodsbyApi
      .request(endpoint, method, body, config)
      .then(response => {
        response = camelize(response)
        return response
      })
      .catch(error => {
        if (
          error instanceof HttpError &&
          (error.code >= 500 || error.code === 404 || error.code === 0)
        ) {
          history.push(ERROR_PATH, {
            error: { message: error.message, code: error.code }
          })
          return Promise.reject()
        } else {
          throw error
        }
      })
  }
}

export const login = (username, password) =>
  foodsbyLogin(
    {
      username,
      password,
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET
    },
    { baseURL }
  )

export const register = body => foodsbyRegister(body, { baseURL })

export default api
