import React from 'react'
import { Button } from '@foodsby/nutrient'
import SplashLayout from 'components/splashLayout/SplashLayout'
import logo from 'assets/foodsby-logo-menu.png'

const ShutdownSplashPage = ({ history }) => {
  const goToOrderPage = () => {
    window.location.href = process.env.REACT_APP_ORDER_URL
  }

  return (
    <SplashLayout className='shutdown-splash-page' headerProps={{ fixedHeader: true }}>
      <div className='shutdown-splash-page__content'>
        <a href={process.env.REACT_APP_ORDER_URL}>
          <img className='shutdown-splash-page__logo' src={logo} alt='Foodsby' />
        </a>
        <h1 className='shutdown-splash-page__header'>Group order unavailable.</h1>
        <div className='shutdown-splash-page__message'>
          <p>
            Group ordering is no longer supported. Please visit our main site to place your order.
          </p>
          <br />
          <p>
            If you have any questions, please reach out to our support team at support@foodsby.com.
          </p>
        </div>
        <div className='shutdown-splash-page__action'>
          <Button onClick={goToOrderPage}>Home</Button>
        </div>
      </div>
    </SplashLayout>
  )
}

export default ShutdownSplashPage
