import React from 'react'
import get from 'lodash/get'

import AppLayout from 'components/appLayout/AppLayout'

const AppErrorPage = ({ currentUser, location, error }) => {
  error = error || get(location, 'state.error')

  let message
  if (error && error.code === 404) {
    message = 'Page not found!'
  } else {
    message = 'Something went wrong!'
  }

  return (
    <AppLayout currentUser={currentUser} headerProps={{ fixedHeader: true }}>
      <div className='app-error-page'>
        <div className='app-error-page__message'>{message}</div>
      </div>
    </AppLayout>
  )
}

export default AppErrorPage
