import { combineReducers } from 'redux'

import configReducer from './modules/config'
import userReducer from './modules/user'
import storeReducer from './modules/store'
import menuReducer from './modules/menu'
import newGroupOrderReducer from './modules/newGroupOrder'
import groupOrderReducer from './modules/groupOrder'
import locationReducer from './modules/location'
import authReducer from './modules/auth'
import applicationReducer from './modules/application'
import alertReducer from './modules/alerts'

const applicationReducers = combineReducers({
  config: configReducer,
  store: storeReducer,
  menu: menuReducer,
  user: userReducer,
  newGroupOrder: newGroupOrderReducer,
  groupOrder: groupOrderReducer,
  location: locationReducer,
  auth: authReducer,
  application: applicationReducer,
  alerts: alertReducer
})

const initialState = {}
const rootReducer = (state = initialState, action) => {
  return applicationReducers(state, action)
}

export default rootReducer
