import { createAction, handleActions } from 'redux-actions'

const SET_NAV_ALERT = 'foodsby/alert/SET_NAV_ALERT'

export const setNavAlert = createAction(SET_NAV_ALERT)

const initialState = {
  navAlert: {}
}

const alertReducers = handleActions(
  {
    [SET_NAV_ALERT]: (state, { payload }) => ({
      navAlert: {
        ...state.navAlert,
        name: typeof payload === 'object' && payload ? payload.name : payload,
        messageOverride:
          typeof payload === 'object' && payload
            ? payload.messageOverride
            : null
      }
    })
  },
  initialState
)

export default alertReducers
