import React from 'react'

import {
  TERMS_AND_CONDITIONS_URL,
  PRIVATE_POLICY_URL,
  CAREERS_URL,
  CONTACT_URL
} from 'routes'

const AppFooter = () => (
  <footer className='app-footer'>
    <div className='app-footer__wrapper'>
      <div className='app-footer__body'>
        <a className='app-footer__link' href={PRIVATE_POLICY_URL}>
          Privacy Policy
        </a>
        <a className='app-footer__link' href={TERMS_AND_CONDITIONS_URL}>
          Terms of Use
        </a>
        <a className='app-footer__link' href={CONTACT_URL}>
          Contact Us
        </a>
        <a className='app-footer__link' href={CAREERS_URL}>
          Careers
        </a>
      </div>
    </div>
  </footer>
)

export default AppFooter
