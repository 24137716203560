import currency from 'currency.js'

export const fromCents = amountInCents => fromDollars(amountInCents / 100)

export const fromCentsToCeil = amountInCents =>
  fromDollars(Math.ceil(amountInCents / 100))

export const fromCentsNoSymbol = amountInCents =>
  currency(amountInCents / 100, { formatWithSymbol: false })

export const fromCentsDollarFormat = amountInCents =>
  currency(amountInCents / 100, { formatWithSymbol: true, precision: 0 })

export const fromCentsDollarFormatNoSymbol = amountInCents =>
  currency(amountInCents / 100, { formatWithSymbol: false, precision: 0 })

export const fromDollars = amountInDollars =>
  currency(amountInDollars, { formatWithSymbol: true })

export const dollarsToCents = amountInDollars =>
  fromDollars(amountInDollars).intValue
