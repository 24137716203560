import { createAction, handleActions } from 'redux-actions'

import { loadUserPayments, loadUserProfile } from 'api'
import { storeUserInfoCookie } from 'utils/cookies'

const SET_CURRENT_USER = 'foodsby/user/SET_CURRENT_USER'
const SET_USER_PROFILE = 'foodsby/user/SET_USER_PROFILE'
const SET_PAYMENTS = 'foodsby/user/SET_PAYMENTS'
const SET_IS_LOADING_USER = 'foodsby/user/SET_IS_LOADING_USER'

export const setCurrentUser = createAction(SET_CURRENT_USER)
export const setUserProfile = createAction(SET_USER_PROFILE)
export const setPayments = createAction(SET_PAYMENTS)
export const setIsLoadingUser = createAction(SET_IS_LOADING_USER)

export const getUserProfile = userId => async dispatch => {
  const data = await loadUserProfile(userId)
  dispatch(setUserProfile(data))
}

export const getPayments = () => {
  return dispatch => {
    return loadUserPayments().then(data => {
      dispatch(setPayments(data))
    })
  }
}

const initialState = {
  currentUser: {},
  userProfile: null,
  payments: null,
  isLoading: true
}

const userReducer = handleActions(
  {
    [SET_CURRENT_USER]: (state, { payload }) => {
      const currentUser = payload
      storeUserInfoCookie({
        userId: currentUser.userId
      })
      return {
        ...state,
        currentUser
      }
    },
    [SET_IS_LOADING_USER]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [SET_USER_PROFILE]: (state, { payload }) => ({
      ...state,
      userProfile: payload
    }),
    [SET_PAYMENTS]: (state, { payload }) => {
      const payments = payload
      return {
        ...state,
        payments
      }
    }
  },
  initialState
)

export default userReducer
