import { createAction, handleActions } from 'redux-actions'

import { loadConfig } from 'api'

const SET_CONFIG = 'foodsby/config/SET_CONFIG'

export const setConfig = createAction(SET_CONFIG)

export const getConfig = () => async dispatch => {
  const data = await loadConfig()
  dispatch(setConfig(data))
}

const initialState = {
  config: {}
}

const configReducer = handleActions(
  {
    [SET_CONFIG]: (state, { payload }) => {
      const config = payload
      return {
        ...state,
        config
      }
    }
  },
  initialState
)

export default configReducer
