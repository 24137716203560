import { createAction, handleActions } from 'redux-actions'

import { loadMenuForStore, loadMenuItem } from 'api'

const SET_MENU = 'foodsby/menu/SET_MENU'
const SET_MENU_ITEM = 'foodsby/menu/SET_MENU_ITEM'

const SET_MEAL = 'foodsby/menu/SET_MEAL'
const CLEAR_MEAL = 'foodsby/menu/CLEAR_MEAL'
const ADD_MEAL_ITEM = 'foodsby/menu/ADD_MEAL_ITEM'
const REMOVE_MEAL_ITEM = 'foodsby/menu/REMOVE_MEAL_ITEM'
const REPLACE_MEAL_ITEM = 'foodsby/menu/REPLACE_MEAL_ITEM'

export const setMenu = createAction(SET_MENU)
export const setMenuItem = createAction(SET_MENU_ITEM)

export const setMeal = createAction(SET_MEAL)
export const clearMeal = createAction(CLEAR_MEAL)
export const addMealItem = createAction(ADD_MEAL_ITEM)
export const removeMealItem = createAction(REMOVE_MEAL_ITEM)
export const replaceMealItem = createAction(REPLACE_MEAL_ITEM)

export const getMenu = storeId => async dispatch => {
  const data = await loadMenuForStore(storeId)
  dispatch(setMenu(data))
}

export const getMenuItem = itemId => async dispatch => {
  const data = await loadMenuItem(itemId)
  dispatch(setMenuItem(data))
}

const initialState = {
  menu: {},
  menuItem: null,
  meal: []
}

const menuReducer = handleActions(
  {
    [SET_MENU]: (state, { payload }) => {
      const menu = payload
      return {
        ...state,
        menu
      }
    },
    [SET_MENU_ITEM]: (state, { payload }) => ({
      ...state,
      menuItem: payload
    }),
    [SET_MEAL]: (state, { payload }) => {
      const meal = payload

      return {
        ...state,
        meal
      }
    },
    [CLEAR_MEAL]: state => {
      return {
        ...state,
        meal: []
      }
    },
    [ADD_MEAL_ITEM]: (state, { payload }) => {
      const mealItem = payload

      return {
        ...state,
        meal: [...state.meal, mealItem]
      }
    },
    [REMOVE_MEAL_ITEM]: (state, { payload }) => {
      const idx = payload
      const meal = [...state.meal]
      meal.splice(idx, 1)

      return {
        ...state,
        meal
      }
    },
    [REPLACE_MEAL_ITEM]: (state, { payload }) => {
      const { mealItem, mealItemIndex } = payload
      const meal = [...state.meal]
      meal[mealItemIndex] = mealItem

      return {
        ...state,
        meal
      }
    }
  },
  initialState
)

export default menuReducer
