import { createAction, handleActions } from 'redux-actions'

const SET_LOADING = 'foodsby/application/SET_LOADING'

export const setLoading = createAction(SET_LOADING)

const initialState = {
  isLoading: false
}

const applicationReducer = handleActions(
  {
    [SET_LOADING]: (state, { payload }) => {
      return {
        ...state,
        isLoading: payload
      }
    }
  },
  initialState
)

export default applicationReducer
